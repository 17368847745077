import { TIME_FORMAT } from "@src/app/dayView/constants";
import { formatDollarsAsUsd } from "@src/appV2/lib/Money/utils/currency";
import { timeRangeFormatter } from "@src/lib/utils";
import moment from "moment-timezone";

import {
  IConvertToIso8601Props,
  IFormatTimeNegotiationStartAndEndTimeProps,
  NegotiationHistoryAction,
  NegotiationRateRole,
  NegotiationType,
} from "./interfaces";

export function isTimeNegotiation(negotiationType?: NegotiationType) {
  return negotiationType === NegotiationType.TIME;
}

export function getHistoryContent({ historyItem, negotiationType, facilityTimezone }): {
  actionSummaryText: string;
  textClass: string;
} {
  let actionSummaryText = "";
  let textClass = "history-text";
  const { createdByUserRole, action, start, end, payRate } = historyItem;
  const formattedPayRate = formatDollarsAsUsd(payRate, { minimumFractionDigits: 0 });
  switch (createdByUserRole) {
    case NegotiationRateRole.ADMIN:
      if (action === NegotiationHistoryAction.INITIAL) {
        const timeRangeContent = timeRangeFormatter(
          TIME_FORMAT,
          {
            start,
            end,
          },
          facilityTimezone
        );
        actionSummaryText = isTimeNegotiation(negotiationType)
          ? `Posted ${timeRangeContent}`
          : `Posted Rate: ${formattedPayRate}/hr`;
      }
      break;
    case NegotiationRateRole.WORKPLACE:
      if (action === NegotiationHistoryAction.PROPOSED) {
        actionSummaryText = `Workplace proposed ${formattedPayRate}/hr`;
      } else if (action === NegotiationHistoryAction.REJECTED) {
        actionSummaryText = "Workplace ended the negotiation";
      }
      break;
    case NegotiationRateRole.WORKER:
      textClass = "history-text-bold";
      if (
        action === NegotiationHistoryAction.PROPOSED ||
        action === NegotiationHistoryAction.STARTED
      ) {
        const timeRangeContent = timeRangeFormatter(
          TIME_FORMAT,
          {
            start,
            end,
          },
          facilityTimezone
        );
        actionSummaryText = isTimeNegotiation(negotiationType)
          ? `You proposed ${timeRangeContent}`
          : `You proposed ${formattedPayRate}/hr`;
      } else if (action === NegotiationHistoryAction.REJECTED) {
        actionSummaryText = "You ended the negotiation";
      } else if (action === NegotiationHistoryAction.BOOKED_CONFLICTING_SHIFT) {
        actionSummaryText = "You booked another shift";
      } else if (action === NegotiationHistoryAction.ACCEPTED) {
        const timeRangeContent = timeRangeFormatter(
          TIME_FORMAT,
          {
            start,
            end,
          },
          facilityTimezone
        );
        actionSummaryText = isTimeNegotiation(negotiationType)
          ? `Booked for ${timeRangeContent}`
          : `Booked at ${formattedPayRate}/hr`;
      } else if (action === NegotiationHistoryAction.BOOKED_BY_HCP) {
        actionSummaryText = "Booked by you";
      }
      break;
  }

  switch (action) {
    case NegotiationHistoryAction.HIDDEN:
      actionSummaryText = `${historyItem.count} more...`;
      textClass = "hidden-history";
      break;
    case NegotiationHistoryAction.BOOKED_BY_ANOTHER_HCP ||
      NegotiationHistoryAction.SHIFT_ASSIGNED_BY_ADMIN:
      actionSummaryText = "Booked by another Worker";
      break;
    case NegotiationHistoryAction.SHIFT_LEAD_TIME_EXPIRED:
      actionSummaryText = "Expired before shift start";
      break;
    case NegotiationHistoryAction.WORKER_SUSPENDED:
      actionSummaryText = "You were Suspended";
      break;
    case NegotiationHistoryAction.WORKPLACE_SUSPENDED:
      actionSummaryText = "Workplace was Suspended";
      break;
    case NegotiationHistoryAction.WORKER_DEACTIVATED:
      actionSummaryText = "You were Deactivated";
      break;
    case NegotiationHistoryAction.WORKPLACE_DEACTIVATED:
      actionSummaryText = "Workplace was Deactivated";
      break;
    case NegotiationHistoryAction.WORKER_BLOCKED:
      actionSummaryText = "You were Blocked by the Workplace";
      break;
    case NegotiationHistoryAction.WORKPLACE_BLOCKED:
      actionSummaryText = "You Blocked the Workplace";
      break;
  }

  return { actionSummaryText, textClass };
}

export const formatNegotiationDate = (dateString: string): string => {
  const date = moment(dateString);
  const now = moment();
  const startOfYesterday = moment().subtract(1, "day").startOf("day");

  if (date.isBefore(startOfYesterday)) {
    return date.format("MM/DD/YY");
  } else if (date.isSame(now, "day")) {
    return `Today ${moment(date).format("hh:mm A")}`;
  } else {
    return `Yesterday ${moment(date).format("hh:mm A")}`;
  }
};

export function formatTimeNegotiationStartAndEndTime(
  props: IFormatTimeNegotiationStartAndEndTimeProps
): string {
  const { start, end, timezone } = props;
  return `${formatTimeInTwelveHourFormat(start, timezone)} - ${formatTimeInTwelveHourFormat(
    end,
    timezone
  )}`;
}

export function formatTimeInTwelveHourFormat(time: string, timezone: string) {
  return moment(time).tz(timezone).format("h:mmA");
}

export function extractLocalTimeInformation(timeInUTC: string, timezone: string) {
  const localDateTime = moment.utc(timeInUTC).tz(timezone);
  const year = localDateTime.format("YYYY");
  const month = localDateTime.format("MM");
  const date = localDateTime.format("DD");
  const hours = localDateTime.format("h").padStart(2, "0");
  const minutes = localDateTime.format("mm");
  const meridiem = localDateTime.format("A");
  return {
    year,
    month,
    date,
    hours,
    minutes,
    meridiem,
  };
}

export function convertToUtcFormat(props: IConvertToIso8601Props) {
  const { timezone, year, month, date, hoursIn12hourFormat, minutes, meridiem } = props;
  const localDateTime = moment.tz(
    `${year}-${month}-${date} ${hoursIn12hourFormat}:${minutes}:00 ${meridiem}`,
    "YYYY-MM-DD hh:mm:ss A",
    timezone
  );

  const utcDateTime = localDateTime.utc().format("YYYY-MM-DDTHH:mm:ss.SSS[Z]");
  return utcDateTime;
}

/**
 * This function generates an array of options for a time picker component based
 * on the provided start and end values.
 * It allows specifying a skip value to determine the step between consecutive options.
 */
export function generateTimePickerOptions(start, end, skip = 1) {
  const length = Math.ceil((end - start + 1) / skip);

  return Array.from({ length }, (_, index) => {
    const value = start + index * skip;
    return { text: value.toString().padStart(2, "0") };
  });
}
