import { RateNegotiationConfigEnum } from "@src/app/rateNegotiation/interfaces";
import { CbhFeatureFlag, useCbhFlags } from "@src/appV2/FeatureFlags";
import { Shift } from "@src/lib/interface/src";
import moment from "moment-timezone";
import { useEffect, useState } from "react";

/**
 * Checks if the "rate negotiation" feature is enabled for this facility based on its attributes.
 */
export const useRateNegotiationIsActive = (shift: Shift): boolean => {
  const ldFlags = useCbhFlags();
  const [isActive, setIsActive] = useState<boolean>(false);

  const leadTime = ldFlags[CbhFeatureFlag.RATE_NEGOTIATION_LEAD_TIME];
  const activationConditionsJson: {
    id: string[];
    state: string[];
    msa: string[];
    global: boolean;
  } = ldFlags[CbhFeatureFlag.RATE_NEGOTIATION_ACTIVATION];

  useEffect(() => {
    const shiftStartTime = shift?.start ?? "";
    const isShiftChargeUpdatedManually = shift?.isChargeUpdatedManually;
    const facilityEnabled = shift?.facility?.rateNegotiation === RateNegotiationConfigEnum.ON;

    const facilityUserIdEnabled = !!(
      shift?.facilityId && activationConditionsJson?.id?.includes(shift?.facilityId.toString())
    );
    const facilityStateEnabled = !!(
      shift?.facility?.fullAddress?.state &&
      activationConditionsJson?.state?.includes(shift?.facility?.fullAddress?.state)
    );
    const facilityMsaEnabled = !!(
      shift?.facility?.fullAddress?.metropolitanStatisticalArea &&
      activationConditionsJson?.msa?.includes(
        shift?.facility?.fullAddress?.metropolitanStatisticalArea
      )
    );

    const startTime = moment(shiftStartTime);
    const difference = startTime.diff(moment().utc(), "hours");
    setIsActive(
      difference >= leadTime &&
        !isShiftChargeUpdatedManually &&
        facilityEnabled &&
        (facilityUserIdEnabled ||
          facilityStateEnabled ||
          facilityMsaEnabled ||
          activationConditionsJson.global)
    );
  }, [shift, leadTime, activationConditionsJson]);

  return isActive;
};
