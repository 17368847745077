import { BookabilityAttributes } from "@src/appV2/OpenShifts/ShiftAction";
import { ClaimShiftResponse } from "@src/appV2/OpenShifts/ShiftAction/types";
import { Shift } from "@src/lib/interface/src";

export enum NegotiationType {
  RATE = "RATE",
  TIME = "TIME",
}

export interface IRateNegotiationBannerProps {
  activeNegotiationsCount: number;
  facilityProposalsCount: number;
  fetchShiftsAndNegotiations: () => void;
  deepLinkNegotiationId?: string | null;
}
export enum NegotiationStatus {
  OPEN = "OPEN",
  EXPIRED = "EXPIRED",
  ACCEPTED = "ACCEPTED",
  REJECTED = "REJECTED",
  CLOSED = "CLOSED",
}

export enum NegotiationRateRole {
  WORKER = "WORKER",
  WORKPLACE = "WORKPLACE",
  ADMIN = "ADMIN",
  NEW_PROPOSAL = "NEW_PROPOSAL",
}

export enum NegotiationHistoryAction {
  INITIAL = "INITIAL",
  STARTED = "STARTED",
  PROPOSED = "PROPOSED",
  ACCEPTED = "ACCEPTED",
  REJECTED = "REJECTED",
  BOOKED_BY_HCP = "BOOKED_BY_HCP",
  BOOKED_BY_ANOTHER_HCP = "BOOKED_BY_ANOTHER_HCP",
  BOOKED_CONFLICTING_SHIFT = "BOOKED_CONFLICTING_SHIFT",
  SHIFT_ASSIGNED_BY_ADMIN = "SHIFT_ASSIGNED_BY_ADMIN",
  HIDDEN = "HIDDEN",
  SHIFT_LEAD_TIME_EXPIRED = "SHIFT_LEAD_TIME_EXPIRED",
  WORKER_SUSPENDED = "WORKER_SUSPENDED",
  WORKPLACE_SUSPENDED = "WORKPLACE_SUSPENDED",
  WORKER_DEACTIVATED = "WORKER_DEACTIVATED",
  WORKPLACE_DEACTIVATED = "WORKPLACE_DEACTIVATED",
  WORKER_BLOCKED = "WORKER_BLOCKED",
  WORKPLACE_BLOCKED = "WORKPLACE_BLOCKED",
}

export type RateNegotiationHistoryResponse = {
  action: NegotiationHistoryAction;
  createdByUserId: string;
  createdByUserRole: NegotiationRateRole;
  payRate?: number;
  start?: string;
  end?: string;
  createdAt?: string;
};

export type NegotiationMetadata = {
  lastOffer: RateNegotiationHistoryResponse;
  shiftDetails?: Partial<Shift>;
};

export interface RateNegotiationSchema {
  _id: string;
  shiftId: string;
  workerId: string;
  workplaceId: string;
  status: NegotiationStatus;
  multiplier: number;
  initialChargeRate: number;
  initialPayRate: number;
  initialStart: string;
  initialEnd: string;
  type: NegotiationType;
  history: RateNegotiationHistoryResponse[];
  metadata: NegotiationMetadata;
}

export interface INegotiationToolProps {
  isOpen: boolean;
  closeModal: () => void;
  shift: Shift;
  showCommitModal: (args: any) => void;
  closeCommitModal: () => void;
  workApproved: boolean;
  updateShiftRateNegotiation: (args: any) => void;
  setRequestLoading: (args: any) => void;
  isLoadingShiftBook?: boolean;
  negotiationType?: NegotiationType;
  shiftBookability?: BookabilityAttributes;
  onBookShift: (shift: ClaimShiftResponse) => void;
}
export interface IRateStepperProps {
  proposedBy: NegotiationRateRole;
  currentRate: number;
  increaseRate: () => void;
  decreaseRate: () => void;
  upperLimit: number;
  lowerLimit: number;
}

export interface IRateNegotiationsListProps {
  onClose?: () => void;
  deepLinkNegotiationId?: string | null;
}

export enum NegotiationCommitType {
  PROPOSE_NEW_RATE = "PROPOSE_NEW_RATE",
  PROPOSE_NEW_TIME = "PROPOSE_NEW_TIME",
  END_NEGOTIATION = "END_NEGOTIATION",
  BOOK = "BOOK",
}
export interface INegotiationCommitModalProps {
  rate?: number;
  formattedTimeText?: string;
  onCommit: () => void;
  closeModal: () => void;
  modalType: NegotiationCommitType;
  requestLoading: boolean;
}

export interface INegotiationButton {
  shiftDetails: Shift;
  workApproved: boolean;
  updateShiftViewRateNegotiation: any;
  isLoadingShiftBook?: boolean;
  shouldOpenNegotiationTool?: boolean;
  isRateNegotiationActive: boolean | undefined;
  shiftBookability?: BookabilityAttributes;
  onBookShift: (shift: ClaimShiftResponse) => void;
}
export interface INegotiationHistoryProps {
  facilityTimezone?: string;
  negotiationType?: NegotiationType;
  history: RateNegotiationHistoryResponse[];
  rateNegotiationStatus?: NegotiationStatus;
  workApproved: boolean;
  openRateNegotiationSupportLink?: () => void;
}

export interface INegotiationShiftsCardProps {
  negotiationShift: Shift;
  fetchNegotiatingShifts: () => void;
  shouldOpenNegotiationTool?: boolean;
  shiftBookability?: BookabilityAttributes;
  isLoadingShiftBookability: boolean;
}

export interface IOpenNegotiationsCount {
  totalNegotiatingShiftsCount: number;
  negotiationsWaitingOnWorker: number;
}

export enum RateNegotiationConfigEnum {
  ON = "ON",
  OFF = "OFF",
}

export interface IFormatTimeNegotiationStartAndEndTimeProps {
  start: string;
  end: string;
  timezone: string;
}

export interface IConvertToIso8601Props {
  timezone: string;
  year: string;
  month: string;
  date: string;
  hoursIn12hourFormat: string;
  minutes: string;
  meridiem: string;
}

export interface IProposeNewRate {
  shiftId: string;
  payRate?: number;
  start?: string;
  end?: string;
  negotiationId: string;
}

export interface ITimeStepperProps {
  facilityTimezone: string;
  currentStart: string;
  currentEnd: string;
  newStartTime: string;
  newEndTime: string;
  setNewStartTime: (value: any) => void;
  setNewEndTime: (value: any) => void;
  disabled: boolean;
}

export enum ShiftTiming {
  START = "START",
  END = "END",
}

export enum Meridiem {
  AM = "AM",
  PM = "PM",
}
