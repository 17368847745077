import { IonButton, IonCol, IonIcon, IonRow, IonText } from "@ionic/react";
import { informationCircle } from "ionicons/icons";
import { FC, useMemo } from "react";

import { formatNegotiationDate, getHistoryContent, isTimeNegotiation } from "./helper";
import {
  INegotiationHistoryProps,
  NegotiationHistoryAction,
  NegotiationStatus,
  RateNegotiationHistoryResponse,
} from "./interfaces";

import "./style.scss";

interface NegotiationHistoryType extends Partial<RateNegotiationHistoryResponse> {
  action: NegotiationHistoryAction;
  count?: number;
}

export const NegotiationHistory: FC<INegotiationHistoryProps> = ({
  facilityTimezone,
  negotiationType,
  history,
  rateNegotiationStatus,
  workApproved,
  openRateNegotiationSupportLink,
}) => {
  const historyList = useMemo(() => {
    let historyList: NegotiationHistoryType[] = [];

    if (rateNegotiationStatus === NegotiationStatus.OPEN && history.length > 4 && !workApproved) {
      historyList.push(
        history[0],
        { action: NegotiationHistoryAction.HIDDEN, count: history.length - 3 },
        history[history.length - 2],
        history[history.length - 1]
      );
    } else {
      historyList = history;
    }
    return historyList;
  }, [history, rateNegotiationStatus, workApproved]);

  const renderHistory = (historyList: NegotiationHistoryType[]) => {
    return historyList?.map((historyItem) => {
      const { actionSummaryText, textClass } = getHistoryContent({
        historyItem,
        negotiationType,
        facilityTimezone,
      });

      return (
        <IonRow className="ion-justify-content-between history-item" key={historyItem.action}>
          <IonCol size="8" className="column">
            <IonText>
              <p className={textClass} data-testid="action-summary">
                {actionSummaryText}
              </p>
            </IonText>
          </IonCol>
          {historyItem?.createdAt && (
            <IonCol size="4" class="ion-text-end" className="column left">
              <IonText>
                <p className={textClass} data-testid="action-date">
                  {formatNegotiationDate(historyItem.createdAt)}
                </p>
              </IonText>
            </IonCol>
          )}
        </IonRow>
      );
    });
  };

  const renderLearnMore = useMemo(() => {
    if (!rateNegotiationStatus && openRateNegotiationSupportLink) {
      return (
        <IonButton
          className="learn-more-item"
          onClick={openRateNegotiationSupportLink}
          expand="full"
        >
          <span className="ion-text-left">
            <IonIcon
              icon={informationCircle}
              mode="ios"
              size="small"
              slot="start"
              className="info-icon"
            />
            <IonText className="learn-more-text" data-testid="learn-more-text">
              {isTimeNegotiation(negotiationType)
                ? "How does proposing alternative times work? Learn more!"
                : "How does rate negotiation work? Learn more!"}
            </IonText>
          </span>
        </IonButton>
      );
    }
  }, [negotiationType, openRateNegotiationSupportLink, rateNegotiationStatus]);

  return (
    <div
      className={`negotiation-history ${
        rateNegotiationStatus !== NegotiationStatus.OPEN || workApproved ? "no-border-bottom" : ""
      }`}
      data-testid="negotiation-history-component"
    >
      {renderHistory(historyList)}
      {renderLearnMore}
    </div>
  );
};
